import { MediaTypeEnum } from '../gql/schema';
import {
  ContactType,
  type BaseInstanceConfig,
} from '../models/BaseInstanceConfig';
import { BaseTourDifficulty } from '../models/BaseTourDifficulty';

enum RND_TOUR_CATEGORIES {
  AKTIVTOUREN = 3,
  MOTORISIERTE_TOUREN = 4,
  PISTE = 5,
  RADTOUREN = 6,
  RUNDGANG = 7,
  WANDERUNG = 8,
  WASSERTOUREN = 9,
  WINTERTOUREN = 10,

  // Aktivtouren
  INLINE_SKATING_TOUR = 11,
  KLETTERTOUR = 12,
  NORDIC_WALKING_TOUR = 13,
  REITTOUR = 14,

  // Motorisierte Touren
  FERIENSTRASSE = 17,
  MOTORRADTOUR = 15,
  OFFROADTOUR = 18,
  WOHNMOBILTOUR = 16,

  // Piste
  BUCKELPISTE = 20,
  SKIPISTE = 19,
  SKIROUTE = 22,
  UEBUNGHANG = 21,
  ZIEHWEG = 23,

  // Radtouren
  GRAVELTOUR = 25,
  MTBTOUR = 26,
  RADFERNWEG = 24,
  RADTOUR = 27,
  RENNRADTOUR = 28,

  // Rundgang
  SPAZIERGANG = 29,

  // Wanderung
  ALPINTOUR = 35,
  BERGTOUR = 37,
  FERNWANDERWEG = 32,
  KLETTERSTEG = 31,
  LEHRPFAD = 36,
  PILGERWEG = 33,

  // Wassertouren
  BOOTSTOUR = 39,
  KAJAKTOUR = 41,
  SEGELTOUR = 40,

  // Wintertouren
  LOIPE = 42,
  RODELABFAHRT = 46,
  SCHNEESCHUHTOUR = 43,
  SKITOUR = 44,
  WINTERWANDERWEG = 45,
}

export const CONFIG: Omit<BaseInstanceConfig, 'instanceId'> = {
  graphqlEndpoint: `https://content-delivery.imxplatform.de/rnd/imxplatform`,
  graphqlBearerToken:
    'eyJraWQiOiJpbXgtY2RhIiwidHlwIjoiSldUIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczpcL1wvcm5kLmlteHBsYXRmb3JtLmRlXC9vYXV0aCIsInN1YiI6IjIxIiwibG5hbWUiOiJ3cy53aGl0ZWxhYmVsLXdpZGdldHMifQ.X19fG8eKjNB3_yTi_-6MlFeEp7i_U9SnFnsknXFAUx8k7TLndXbTWg11cj5TPDtOoAioeWKjJE2sRKiMtGofr6XaMWltqME3xGxwHV7xSEIkXUT4znvaBBSPJKcR2PGWRfBfsLLSsMUzPylJMXA3WTDjgGltp2OV_w1GNDmayzxkjT1EVK8fvqXwFrnLNpEddDRMYnI9QfG8BmSQsXoOOYykr4Um20GTx9gMyUL2i9clJkuQPAFVSr1kbr44Vk0aU9M2_MozNl9Iv4CQNnzXoJDk71j9hiWYlR_6LWinONMvUWJ-sMmZSpploCV4BRCpFBheVEegooVUH7y09d2GRA',
  defaultWidgetId: 1,
  enabledLanguages: ['de', 'en'],
  headerLogo: '/assets/images/instances/rnd/logo.colored.svg',
  footerLogo: '/assets/images/instances/rnd/logo.white.svg',
  footerContact: {
    name: 'RedaktionsNetzwerk Deutschland',
    street: 'August-Madsack-Str. 1',
    zipcode: '30559',
    city: 'Hannover',
    email: 'info@madsack.de',
  },
  map: {
    defaultCoords: {
      // freiburg
      latitude: 52.3671713044339,
      longitude: 9.73699056030826,
    },
  },
  defaults: {
    hero: {
      filter: { mediaType: [MediaTypeEnum.Image], imageType: { eq: 1 } },
    },
    gallery: {
      filter: {
        or: [
          { mediaType: [MediaTypeEnum.Image], imageType: { eq: 1 } },
          { mediaType: [MediaTypeEnum.Video] },
        ],
      },
    },
    list: {
      filter: {
        mediaType: [MediaTypeEnum.Image],
        imageType: { eq: 1 },
      },
      fallback: [{ mediaType: [MediaTypeEnum.Image], imageType: { eq: 2 } }],
    },
    article: {
      contact: ContactType.CONTACT,
    },
  },
  tour: {
    categoryIcons: {
      [RND_TOUR_CATEGORIES.AKTIVTOUREN]: 'ion:active',
      [RND_TOUR_CATEGORIES.MOTORISIERTE_TOUREN]: 'ph:motorcycle-fill',
      [RND_TOUR_CATEGORIES.PISTE]: 'lets-icons:winter',
      [RND_TOUR_CATEGORIES.RADTOUREN]: 'ion:bicycle',
      [RND_TOUR_CATEGORIES.RUNDGANG]: 'icon-park-solid:turn-around',
      [RND_TOUR_CATEGORIES.WANDERUNG]: 'material-symbols:hiking',
      [RND_TOUR_CATEGORIES.WASSERTOUREN]: 'ion:boat-outline',
      [RND_TOUR_CATEGORIES.WINTERTOUREN]: 'lets-icons:winter',
    },
    difficultyMapping: {
      1: BaseTourDifficulty.EASY,
      2: BaseTourDifficulty.EASY,
      3: BaseTourDifficulty.MEDIUM,
      4: BaseTourDifficulty.HARD,
      5: BaseTourDifficulty.HARD,
    },
  },
  poi: {
    openingHours: {
      hideDateRangeInfo: true,
    },
  },
};
